import "./styles.scss";

import React from "react";

import SectionHeader from "components/SectionHeader";
import GallerySlider from "components/GallerySlider";

const mainClass = "home-gallery";

const gallery_data = [
  {
    category: "Kyriad Karkonosze",
    slides: new Array(15)
      .fill()
      .map(
        (_, index) => require(`images/gallery/stage1/${index + 1}.jpg`).default
      ),
  },
  {
    category: "KaiZEN",
    slides: [
      ...new Array(4)
        .fill()
        .map(
          (_, index) => require(`images/view_02-25_${index + 1}.jpg`).default
        ),
      ...new Array(11)
        .fill()
        .map(
          (_, index) =>
            require(`images/gallery/stage2/${index + 1}.jpg`).default
        ),
    ],
  },
];

const Gallery = () => {
  return (
    <section className={mainClass} id="galeria-section">
      <SectionHeader title="Galeria" variant="margin-small" />
      <GallerySlider data={gallery_data} />
    </section>
  );
};

export default Gallery;
