/* global google */
import "./styles.scss";

import React, { useState, useEffect } from "react";
import { withPrefix } from "gatsby";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import { mapStyles } from "utils/map";

import markers from "libs/legend_data";

const DEFAULT_MARKER = { lat: 50.88089148217049, lng: 15.632858555002613 };

const LocationMap = () => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(DEFAULT_MARKER);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDNFJB1acI3G5H1JBkWHjDYJgLkRAzA4Vg",
  });

  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      markers.forEach(({ items }) => {
        items.forEach((marker) => {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        });
      });

      map.fitBounds(bounds);
    }
  }, [map]);

  const onLoad = (instance) => {
    setMap(instance);
  };

  return (
    <div className="location-map">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={center}
          zoom={11}
          options={{
            clickableIcons: false,
            styles: mapStyles,
          }}
          onLoad={onLoad}
        >
          <Marker
            icon={{
              url: withPrefix("/map-marker.png"),
              scaledSize: new window.google.maps.Size(60, 72),
              anchor: new window.google.maps.Point(30, 36),
            }}
            position={{ lat: 50.88089148217049, lng: 15.632858555002613 }}
          />
          {markers.map(({ color, items }) =>
            items.map((marker, index) => (
              <Marker
                key={marker.name}
                position={{
                  lat: marker.lat,
                  lng: marker.lng,
                }}
                icon={{
                  url:
                    "data:image/svg+xml;charset=UTF-8," +
                    encodeURIComponent(`
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                              <circle cx="18" cy="18" r="17" fill="${color.background}" stroke="${color.border}" stroke-width="1"/>
                            </svg>
                          `),
                  scaledSize: new window.google.maps.Size(36, 36),
                  anchor: new window.google.maps.Point(18, 18),
                }}
                label={{
                  text: `${index + 1}`,
                  color: color.font,
                  fontSize: "16px",
                }}
                zIndex={marker.name === selectedMarker?.name ? 1000 : undefined}
                onClick={() => {
                  const coordinates = {
                    lat: marker.lat,
                    lng: marker.lng,
                  };

                  setSelectedMarker({
                    name: marker.name,
                    coordinates,
                  });
                  setCenter(coordinates);
                }}
              />
            ))
          )}

          {selectedMarker && (
            <InfoWindow
              position={selectedMarker.coordinates}
              options={{
                pixelOffset: new window.google.maps.Size(0, -16),
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div className="location-map__info">
                <p>{selectedMarker.name}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default LocationMap;
