import "./styles.scss";
import React from "react";

import SectionHeader from "components/SectionHeader";
import CircleIcon from "components/CircleIcon";

import RoomImage from "images/view_02-25_2.jpg";

import AgreementIcon from "icons/features/agreement.svg";
import CalendarMoneyIcon from "icons/features/calendar-money.svg";
import DocumentsIcon from "icons/features/documents.svg";
import WithdrawIcon from "icons/features/withdraw.svg";
import ApartmentIcon from "icons/features/apartment.svg";
import DealIcon from "icons/features/deal.svg";

const mainClass = "home-buy-process";

const process_data = [
  {
    icon: <AgreementIcon />,
    text: "Zdalne lub osobiste podpisanie umowy rezerwacyjnej",
  }, 
  {
    icon: <CalendarMoneyIcon />,
    text: "Wpłata zadatku w ciągu 3 dni od podpisania umowy",
  },
  {
    icon: <DocumentsIcon />,
    text: "Umowa notarialna przenosząca własność",
  },
  {
    icon: <WithdrawIcon />,
    text: "Wpłaty transz wg. ustalonego kalendarza wpłat",
  },
  {
    icon: <ApartmentIcon />,
    text: "Odbiór apartamentu i przekazanie pod zarządzanie operatora",
  },
  {
    icon: <DealIcon />,
    text: "Wypoczywaj i zarabiaj",
  },
];

const BuyProcess = () => {
  return (
    <section id="proces-zakupu-section" className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__post`}>
          <div className={`${mainClass}__post__text`}>
            <h4>Inwestując w nasze apartamenty otrzymujesz:</h4>
            <ul>
              <li>Apartament ze stopą zwrotu do 8%</li>
              <li>Indeksacja o inflację (ochrona kapitału w czasie)</li>
              <li>Inwestycję na okres minimum 10 lat</li>
              <li>Kwartalna wypłata stopy zwrotu</li>
              <li>Możliwość zwrotu 23 % VAT</li>
              <li>Do 30 dni pobytu właścicielskiego</li>
              <li>Pełna własność lokalu z wyodrębnioną księgą wieczystą</li>
              <li>
                Doświadczenie Louvre Hotels Group, drugiej największej sieci
                hotelarskiej na świecie oraz renoma rozpoznawalnej marki Kyriad.
              </li>
            </ul>
          </div>
          <div className={`${mainClass}__post__image`}>
            <img src={RoomImage} alt="" />
          </div>
        </div>
        <SectionHeader title="Proces zakupu" />
        <div className={`${mainClass}__items`}>
          {process_data?.map(({ icon, text }, index) => (
            <div key={index} className={`${mainClass}__item`}>
              <CircleIcon icon={icon} variant="white" size="medium" />
              <h2>{index + 1}</h2>
              <span>{text}</span>

              {/* {index + 1 !== process_data?.length && <ArrowIcon />} */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BuyProcess;
