import "./styles.scss";

import React, { useState, useRef } from "react";
import Slider from "react-slick";

import Button from "components/Button";
// import FormContent from "components/Form";

import KyriadLogo from "icons/kyriad-logo.svg";

import IcoSlickPrev from "icons/slick-prev.svg";
import IcoSlickNext from "icons/slick-next.svg";

const mainClass = "home-hero";

const slides = [
  {
    background: require("images/view_02-25_1.jpg").default,
    title: "KaiZEN",
    description: "Nowa inwestycja już w sprzedaży",
    buttons: [
      {
        label: "Sprawdź!",
        url: "/#kaizen",
      },
      {
        label: "Formularz kontaktowy",
        url: "/#kontaktform2",
        variant: "white",
        isMobile: true,
      },
    ],
  },
  {
    background: require("images/home-bg.jpg").default,
    logo: {
      element: <KyriadLogo />,
      url: "https://www.kyriadkarkonosze.pl/",
    },
    title: "Zyskaj stałą stopę zwrotu do 8% zysku w skali roku!",
    description: "Ostatnie dostępne apartamenty w sprzedaży!",
    buttons: [
      {
        label: "Sprawdź swój zysk!",
        url: "/#kalkulator-zyskow",
      },
      {
        label: "Formularz kontaktowy",
        to: "/#kontaktform2",
        variant: "white",
        isMobile: true,
      },
    ],
  },
];

const Hero = () => {
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    afterChange: function (index) {
      setCurrentSlide(index);
    },
  };

  return (
    <section className={mainClass}>
      <Slider ref={sliderRef} {...settings}>
        {slides.map((item, index) => (
          <div key={index}>
            <div
              className={`${mainClass}__wrapper`}
              style={{
                backgroundImage: `url('${item.background}')`,
              }}
            >
              <div className="container">
                <div className={`${mainClass}__wrapper-inner`}>
                  {item?.logo && (
                    <div className={`${mainClass}__logo`}>
                      <a href={item.logo.url} rel="nofollow noreferrer">
                        {item.logo.element}
                      </a>
                    </div>
                  )}
                  <h2 className="h1">{item.title}</h2>
                  <p>{item.description}</p>
                  {item?.buttons?.length > 0 && (
                    <div className={`${mainClass}__actions`}>
                      {item.buttons.map((button, index) => (
                        <Button
                          className={button.isMobile ? "mobile" : ""}
                          href={button.url}
                          key={index}
                        >
                          {button.label}
                        </Button>
                      ))}
                    </div>
                  )}
                  <div className="home-hero__nav">
                    <button
                      onClick={() => {
                        sliderRef.current.slickGoTo(currentSlide + 1);
                      }}
                    >
                      <IcoSlickPrev />
                    </button>
                    <button
                      onClick={() => {
                        sliderRef.current.slickGoTo(currentSlide - 1);
                      }}
                    >
                      <IcoSlickNext />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* <div className="home-hero__form">
        <div className="container">
          <div className="home-hero__form-inner">
            <FormContent id="kontaktform-section" />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Hero;
